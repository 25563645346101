
@font-face {
    font-family: 'Lexend';
    src: url(../fonts/Lexend/Lexend-Light.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 300;
}
@font-face {
    font-family: 'Lexend';
    src: url(../fonts/Lexend/Lexend-Regular.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 400;
}
@font-face {
    font-family: 'Lexend';
    src: url(../fonts/Lexend/Lexend-Medium.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 500;
}
@font-face {
    font-family: 'Lexend';
    src: url(../fonts/Lexend/Lexend-SemiBold.ttf);
    font-style: normal;
    font-display: swap;
    font-weight: 600;
}



@font-face {
    font-family: 'BankGothic';
    src: url(../fonts/BankGothic/bankgothicmdbt_medium.otf);
    font-style: normal;
    font-display: swap;
    font-weight: 700;
}
