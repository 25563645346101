@import 'base/reset';
@import 'base/fonts';
@import 'base/grid';

$color_theme: #15E1EA;

body {
  font: 400 16px Lexend, sans-serif;
  line-height: 1.4;
  color: #02050E;
}
button,path,circle,input[submit] {
  transition: .2s;
}
input {
  font: 400 16px Lexend, sans-serif;
}
button,input[type="submit"] {
  font: 400 14px Lexend, sans-serif;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
button,input[type="submit"],a {
  &:hover {
    transition: .2s;
  }

}
path,rect {
  transition: .2s;
}
input[type="submit"] {
  -webkit-appearance: none;
}
input,textarea {
  border-radius: 0;
}
// =======


header.header {
  padding: 5px 0;
  background-color: #001737;
  a {
    color: #fff;
    &:hover {
      color: $color_theme;
    }
  }
  .logo {
    margin-right: 60px;
    width: 85px;
    img {
      width: 100%;
    }
  }
  .main-menu {
      list-style: none;
      display: flex;
      column-gap: 30px;
      a {
          font: normal 20px 'BankGothic', sans-serif;
      }
  }
  .menu-toggle-wrapper {
    display: none;
    .btn-menu-toggle {
      padding: 0;
      border: none;
      margin: 0;
    }
  }
  .search-wrap {
    width: 200px;
    input {
      padding-left: 35px;
      background: transparent url(../images/icons/search.svg) 0 50% no-repeat;
      color: #fff;
      width: 100%;
      &::placeholder {
        color: #fff;
      }
    }
  }
  
}

.description-title {
  font: normal 35px 'BankGothic', sans-serif;
  margin: 80px 0 45px;
}
.description-subtitle {
  font: normal 22px 'BankGothic', sans-serif;
  margin-bottom: 30px;
}

.title-section {
  font: normal 50px 'BankGothic', sans-serif;
  span {
    color: $color_theme;
  }
}


.first-window-title {
  color: $color_theme;
  font: normal 60px 'BankGothic', sans-serif;
}
.first-findow-second-title {
  color: $color_theme;
  font: normal 50px 'BankGothic', sans-serif;

}
.first-window-subtitle {
  color: #fff;
  font: normal 18px 'BankGothic', sans-serif;
  margin: 5px 0 48px;
}

.first-window-home {
  background: radial-gradient(574.56% 155.46% at 113.68% 130.53%, #08090B 0%, #001737 100%);
  padding: 125px 0;
  position: relative;
  .list-items {
    margin-top: 80px;
    .item {
      margin-bottom: 24px;
      font-weight: 300;
      color: #fff;
      span {
        color: $color_theme;
        font: normal 20px 'BankGothic', sans-serif;
      }
    }
  }
  .image-decor {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 60%;
  }
}
.first-window-default {
  background: radial-gradient(574.56% 155.46% at 113.68% 130.53%, #08090B 0%, #001737 100%);
  padding: 125px 0;
  position: relative;
  overflow: hidden;
  
  .image-decor {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    bottom: -10px;
    opacity: .7;
    max-width: 900px;
  }
}
.btn {
  display: inline-block;
  padding: 4px 4px 4px 24px;
  color: #02050E;
  background-color: $color_theme;
  border-radius: 4px;
  font-weight: 500;
  .ico {
    margin-left: 24px;
    display: inline-flex;
    width: 48px;
    height: 48px;
    background-color: #074751;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border-radius: 4px;
  }
  &:hover {
    background-color: #074751;
    color: $color_theme;
  }
}
.mt40 {
  margin-top: 40px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}


.post-single-container {
  padding: 120px 0;
  &.home {
    background: radial-gradient(539.13% 240.67% at -5.57% -35.47%,#08090b 0,#001737 100%);
    .title,.date-posted,.subtitle,.excerpt-text {
      color: #fff;
    }
  }
  .video-block {
    border-radius: 24px;
    overflow: hidden;
    height: 100%;
    position: relative;
    min-height: 400px;
    .video-thumb {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btn-play {
      position: absolute;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }
  }
  .title {
    font: normal 50px 'BankGothic', sans-serif;
    color: #02050E;
  }
  .subtitle {
    font: normal 35px 'BankGothic', sans-serif;
    color: #02050E;
    margin-top: 80px;
    span {
      color: $color_theme;
    }
  }
  .date-posted {
    margin-top: 10px;
    font: normal 18px 'BankGothic', sans-serif;
    color: #02050E;
    span {
      color: $color_theme;
    }
  }
  .excerpt-text {
    font-weight: 300;
    margin-top: 10px;
  }
}


.main-video-wrapper {
  position: relative;

  .title-section-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    top: 30px;
    z-index: 2;
    p {
      color: #fff;
      font-weight: 300;
    }
  }
  .title-section {
    font: normal 50px 'BankGothic', sans-serif;
    color: #fff;
    span {
      color: $color_theme;
    }
  }
  .main-video {
    position: relative;
  }
  .btn-play {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    z-index: 2;
  }
  .video-thumb {
    width: 100%;
    display: block;
  }
}

.blog-section {
  padding: 120px 0;
  .item-blog {
    margin-bottom: 40px;
    .thumb {
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .title {
      margin-top: 24px;
      a {
        color: $color_theme;
        font-weight: 500;
        font-size: 24px;
        &:hover {
          color: #02050E;
        }
      }
    }
    .excerpt {
      font-weight: 300;
      margin-top: 10px;
    }
  }
}

.about-us-section {
  padding: 120px 0;
  &.home {
    background-color: #001737;
    .title,.text {
      color: #fff;
    }
  }
  .video-block {
    border-radius: 24px;
    overflow: hidden;
    height: 100%;
    position: relative;
    min-height: 570px;
    .video-thumb {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btn-play {
      position: absolute;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }
  }
  .title {
    font: normal 50px 'BankGothic', sans-serif;
  }
  .text {
    margin-top: 15px;
    font-weight: 300;
  }
}

.advantages-section,.advantages-section-2 {
  background: radial-gradient(539.13% 240.67% at -5.57% -35.47%, #08090B 0%, #001737 100%);
  position: relative;
  padding: 80px 0;
  .image-decor {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 80%;
  }
  
  .title-section {
    font: normal 50px 'BankGothic', sans-serif;
    color: #fff;
    span {
      color: $color_theme;
    }
  }
  .item-advant {
    margin-bottom: 30px;
    .title-advant {
      font: normal 35px 'BankGothic', sans-serif;
      color: #fff;
      margin-bottom: 10px;
    }
    .text-advant {
      color: #B3B7BD;
      font-weight: 300;
    }
  }
}
.advantages-section {
  .image-decor-2 {
    position: absolute;
    right: -14px;
    top: 17px;
    opacity: .5;
    transform: rotate(90deg);
  }
}
.advantages-section-2 {
  .image-decor {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    height: 60%;
  }
  .image-decor-2 {
    position: absolute;
    left: 0;
    top: 0;
    opacity: .5;
  }
  .title-section {
    margin-bottom: 40px;
  }
}

.main-title-section {
  margin: 80px 0 50px;
  font-size: 44px;
  span {
    color: $color_theme;
  }
}

.list-posts {
  padding: 80px 0;
  .item-post {
    height: 375px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    border-radius: 24px;
    overflow: hidden;
    padding: 30px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
    .name {
      color: $color_theme;
      font-size: 44px;
      position: relative;
    }
  }
}

.list-carousel-wrapper {
  padding: 26px 0 60px;
  .slick-slide {
    padding: 0 24px;
  }
  .item {
    .thumb {
      border-radius: 24px;
      overflow: hidden;
      position: relative;
    }
    .btn-play {
      position: absolute;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }
  }
  .slick-prev,.slick-next {
    top: 35%;
    width: 48px;
    height: 48px;
    background-color: #fff;
    border: 1px solid #15E1EA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    &::before {
      content: none;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .title {
    margin-top: 15px;
    a {
      font-size: 22px;
      color: #02050E;
      text-transform: uppercase;
      &:hover {
        color: $color_theme;
      }
    }
  }
}

.where-do-section {
  padding: 60px 0;
  .title-section {
    margin-bottom: 15px;
  }
  p {
    font-weight: 300;
  }
}

.list-products-section {
  padding: 140px 0 80px;
  .sorting-row {
    margin-bottom: 40px;
  }
  .sorting-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .label-sort {
    font: normal 18px 'BankGothic', sans-serif;
  }
  .nice-select {
    border-color: $color_theme;
    height: 56px;
    padding-top: 6px;
    width: 260px;
    .list {
      width: 100%;
    }
    &:after {
      border-color: #fff;
      border-width: 2px;
      width: 15px;
      height: 15px;
      right: 20px;
      top: 40%;
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      right: 4px;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      background-color: $color_theme;
    }
  }
  .item-product-wrapper {
    margin-bottom: 30px;
    .item-product {
      padding: 24px;
      border: 2px solid $color_theme;
      border-radius: 16px;
    }
    .thumb {
      a {
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .name-product {
      margin: 20px 0 10px;
      a {
        color: #02050E;
        font: normal 22px 'BankGothic', sans-serif;
        &:hover {
          color: $color_theme;
        }
      }
    }
    .price {
      color: $color_theme;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
  .pagination-row {
    justify-content: flex-end;
  }
}

.pagination-row {
  display: flex;
  column-gap: 24px;
  a {
    width: 48px;
    height: 48px;
    padding: 5px 0;
    border-radius: 8px;
    text-align: center;
    border: 1px solid $color_theme;
    font-size: 24px;
    color: #02050E;
    &:hover,&.active {
      background-color: $color_theme;
    }
  }
  .link-next,.link-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.card-info-section {
  padding: 80px 0;
  .thumb-wrapper {
    padding: 15px;
    position: relative;
    border: 2px solid $color_theme;
    border-radius: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .label {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 7px 15px;
      border-top-right-radius: 16px;
      background-color: $color_theme;
      font-weight: 300;
      a {
        color: #02050E;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .price {
    color: $color_theme;
    font: normal 35px 'BankGothic', sans-serif;
    margin: 20px 0;
  }
  .short-desc {
    font-weight: 300;
    margin-top: 10px;
  }
  .right-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .btn {
      span {
        height: 40px;
      width: 40px;
      }
    }
  }
  .main-video {
    border-radius: 16px;
    overflow: hidden;
  }

  .list-character {
    padding-top: 40px;
    .item-character {
      margin: 20px 0;
      
    }
    .item-row {
      &:nth-child(even) {
        .item-character {
          &:first-child {
            order: 1;
          }
        }
      }
    }
    .text {
      font: normal 22px 'BankGothic', sans-serif;
      line-height: 1.4;
    }
    .character-thumb {
      border-radius: 24px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .list-products-section {
    padding-top: 40px;
  }
  .banner-section {
    margin-top: 40px;
  }
}
.banner-section {
  border-radius: 24px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.counter-product {
  display: flex;
  align-items: center;
  input {
    width: calc(100% - 96px);
    border-top: 1px solid #02050E;
    border-bottom: 1px solid #02050E;
    font-weight: 700;
    padding: 7px;
    text-align: center;
  }
  button {
    font-size: 36px;
    color: #02050E;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: $color_theme;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-top: -5px;
    }
  }
}



footer.footer {
  padding: 80px 0 60px;
  background-color: #001737;
  color: #fff;
  .title-menu {
    font: normal 20px 'BankGothic', sans-serif;
    margin-bottom: 30px;
  }
  ul {
    list-style: none;
  }
  a {
    color: #fff;
    font-weight: 300;
    &:hover {
      color: $color_theme;
    }
  }
  .subtext {
    margin-bottom: 40px;
    margin-top: -15px;
    font-weight: 300;
  }
  .with-icons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 15px;
    margin-bottom: 30px;
    li {
      .ico {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    &.contacts {
      display: block;
      li {
        margin-bottom: 10px;
      }
    }
  }
  .default-menu {
    li {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.hidden-pc {
  display: none;
}


@import 'base/media';