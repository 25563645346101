@media (max-width: 992px) {
    header.header {
        position: sticky;
        top: 0;
        z-index: 9;
        .logo {
            height: 70px;
            margin-right: 0;
        }
        .search-wrap {
            width: auto;
            margin-right: -15px;
            margin-top: -3px;
            input {
                width: 37px;
                transition: .2s;
                padding-top: 4px;
                padding-bottom: 4px;
                border-bottom: 1px solid transparent;
                &:focus {
                    width: 200px;
                    border-color: #fff;
                }
            }
        }
        .main-menu {
            background-color: #011d43;
            padding: 15px;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: calc(100vh - 80px);
            flex-direction: column;
            align-items: center;
            display: none;
            li {
                padding: 20px 0;
                text-align: center;
            }
        }
        .menu-toggle-wrapper {
            display: block;
        }
    }

    .first-window-title {
        font-size: 40px;
    }

    .first-window-home {
        padding: 80px 0;
        .image-decor {
            opacity: .5;
            display: flex;
            align-items: flex-end;
            img {
                max-height: 70%;
            }
        }
        .first-window-container {
            position: relative;
        }
        .btn-wrapper,.first-window-title,.first-window-subtitle {
            text-align: center;
        }
    }

    .post-single-container {
        padding: 60px 0;
        .title {
            font-size: 32px;
        }
        .date-posted {
            margin-bottom: 20px;
        }
        .video-block {
            height: 250px;
            min-height: auto;
        }
        .subtitle {
            margin-top: 20px;
        }
    }
    .blog-section {
        padding: 60px 0;
    }
    .hidden-pc {
        display: block;
    }
    .hidden-m {
        display: none;
    }
    .about-us-section {
        padding: 60px 0;
        .title {
            font-size: 32px;
        }
    }
    .advantages-section,.advantages-section-2 {
        overflow: hidden;
        padding: 60px 0;
        .title-section {
            font-size: 40px;
            text-align: center;
            color: $color_theme;
            margin-bottom: 30px;
        }
        .item-advant {
            .title-advant {
                font-size: 24px;
            }
        }
        .image-decor {
            opacity: .5;
            height: auto;
            left: auto;
        }
        
    }
    .advantages-section-2 {
        .image-decor {
            display: none;
        }
    }

    .order-1 {
        order: 1;
    }
    .order-2 {
        order: 2;
    }

    .first-window-default {
        padding: 80px 0;
    }
    .main-video-wrapper {
        .title-section {
            font-size: 18px;
        }
        .video-thumb {
            display: block;
        }
    }
    .card-info-section {
        .list-character {
            .item-row {
                .item-character {
                    &:first-child {
                        order: 1;
                    }
                }
            }
        }
    }
    
    .where-do-section {
        padding: 0;
    }
    .title-section,.main-title-section {
        font-size: 40px;
    }
    .list-carousel-wrapper {
        .item {
            .thumb {
                img {
                    width: 100%;
                }
            }
        }
    }
    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 5px 20px;
    }
    .list-posts {
        .item-post {
            height: 200px;
            text-align: center;
            .name {
                font-size: 38px;
            }
        }
    }
    .list-products-section {
        padding: 60px 0;
        .nice-select {
            margin-top: 15px;
            width: 100%;
        }
        .pagination-row {
            justify-content: center;
            column-gap: 15px;
        }
    }
    .item-product-wrapper {
        .item-product {
            .btn {
                width: 100%;
            }
        }
    }
    
    .pagination-row {
        a {
            width: 38px;
            height: 38px;
            padding: 0;
        }
    }
    .card-info-section {
        .counter-product {
            margin-bottom: 20px;
        }
        .title-section {
            margin-top: 20px;
        }
        .right-block {
            .btn {
                width: 100%;
            }
        }
    }

    footer.footer {
        position: relative;
        padding: 48px 0;
        .logo-footer {
            position: absolute;
            right: 30px;
            bottom: 50px;
            width: 140px;
        }
    }
    
}